import { Chip, Divider, Grid, Box, TextField, Button } from "@mui/material"
import ChipInformation from "./chipInformation";
import { useContext, useState } from "react";
import { apiUrl, GlobalContext } from "../App";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export default function InformationPanel({ data, setCurrentHighlightIdx, setCurrentHighlightName, setCurrentHighlightId, pdfName }) {
    console.log(data)

    const { specificMode, setSpecificMode, setSources } = useContext(GlobalContext)

    const displayKeysList = ['characters', 'assets', 'decors']

    // const [currentHighlightIdx, setCurrentHighlightIdx] = useState(0);
    // const [currentHighlightName, setCurrentHighlightName] = useState('');

    const [newTag, setNewTag] = useState('');
    const [isAddingTag, setIsAddingTag] = useState(false);
    const [currentAddingCategory, setCurrentAddingCategory] = useState('');

    function onTagClick(tag) {
        if (specificMode !== 'indiv') {
            setSpecificMode('indiv')
        }
        setCurrentHighlightIdx(0);
        setCurrentHighlightName(tag);
        setCurrentHighlightId(data.highlights_depouillement_ids[tag][0]);
    }

    function onNewTagClick() {

        fetch(apiUrl + '/addNewTag/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                pdfName: pdfName,
                tag: newTag,
                category: currentAddingCategory
            })
        }).then(response => response.json())
            .then(data => {
                console.log('data:', data);
                setSources(data)
                // data.depouillement[key].push(newTag)
                setNewTag('')
                setIsAddingTag(false)
            });
    }


    function depouillementToCSV(dict) {
        // Create a new dict without the resume and events keys
        const newDict = Object.keys(dict).reduce((acc, key) => {
            if (key !== 'resume') {
                acc[key] = dict[key];
            }
            return acc;
        }, {});

        let csv = 'Categorie,Item\n';
        console.log(dict)
        Object.keys(newDict).forEach(key => {
            newDict[key].forEach(item => {
                csv += `"${key}","${item}"
`;
            });
        });

        // Add resume to the end of the csv
        csv += `"Resume","${dict.resume}"\n`;

        // Create a Blob from the CSV string
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);

        // Create a temporary <a> element and trigger download
        const a = document.createElement('a');
        a.href = url;
        a.download = pdfName + '_depouillement.csv';
        document.body.appendChild(a);
        a.click();

        // Cleanup
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
        console.log(csv);
        return csv;
    }



    return (
        // textallign left
        <Grid container item sx={{
            overflow: 'auto',
            height: '100%'
        }}
            justifyContent='center'
        >


            <Grid item>
                <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                        {/*
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Show - Ep. XXX - Nom de l'épisode
                            </Typography>
                        */}
                        <Typography variant="h5" component="div">
                            Résumé
                        </Typography>
                        <Typography variant="body2">
                            {data.depouillement.resume}
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid container item>
                {
                    displayKeysList.map((key, index) => {

                        let currentLabel = key.charAt(0).toUpperCase() + key.slice(1)
                        if (currentLabel === 'Assets') {
                            currentLabel = 'Props'
                        }

                        return (

                            <Box key={index} sx={{ width: "100%" }}>
                                <Divider sx={{ padding: "2em" }}>
                                    <Chip label={currentLabel} size="small" />
                                </Divider>
                                {data.depouillement[key].map((search, index) => {
                                    return (
                                        <ChipInformation key={index} idx={index} search={search} highlightIds={data.highlights_depouillement_ids[search]}
                                            onClick={() => onTagClick(search)}
                                        />
                                    )
                                })}
                                {isAddingTag && currentAddingCategory === key &&
                                    <TextField
                                        id="outlined-basic"
                                        label="New Tag"
                                        variant="outlined"
                                        value={newTag}
                                        focused
                                        onChange={(e) => setNewTag(e.target.value)}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                onNewTagClick()
                                            }
                                        }}
                                    />

                                }
                                {!isAddingTag &&
                                    <ChipInformation key={data.depouillement[key].length + 1} idx={data.depouillement[key].length + 1} search={'+'}
                                    onClick={() => {
                                        setIsAddingTag(true)
                                        setCurrentAddingCategory(key)
                                    }}    
                                />
                                }
                            </Box>
                        )
                    })}
            </Grid>

            <Grid item>
                <Divider sx={{ padding: "1em" }}>
                </Divider>

                <Button
                    variant="contained"
                    onClick={() => {
                        depouillementToCSV(data.depouillement)
                    }
                    }>
                    Télécharger le csv
                </Button>
            </Grid>


        </Grid >
    )
}